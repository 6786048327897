import { Row, Col, Card, Space, Button, Upload, message, Form } from "antd";
import React, { useContext } from "react";
import IntlMessages from "../../../../../../util/IntlMessages";
import { CheckOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { validateFiles } from "../../../../../../components/UploadAsset/validators";
import { UPLOAD_MAX_FILE_SIZE_MB } from "../../../../../../components/UploadAsset/upload-asset.constants";
import { fileToBase64 } from "../../../../../../util/file";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { getSafeISOLanguage } from "../../../../../../lngProvider";
import ImportPlayersTable from "../components/ImportPlayersTable";
import { ImportPlayersContext, playersToMutation } from "../context";
import { IMPORT_SUB_EVENT_SUBSCRIPTIONS } from "../../../../../../apollo/mutation/sub-event";
import ValidationErrors from "../components/ValidationErrors";
import { cleanObject } from "../../../../../../util/object";
import TipsCard from "../../../../../../components/TipsCard";

const ACCEPTED_FILE_MIMES = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const PROCESS_SUB_EVENT_SUBSCRIPTIONS_IMPORT = gql`
    query processSubEventSubscriptionsImport($id: String!, $file: String!) {
        processSubEventSubscriptionsImport(id: $id, file: $file) {
            players {
                index
                first_name
                last_name
                email
                gender
                phone
                birthday
                citizenship
                location {
                    id
                    name
                }
                product {
                    id
                    variant_sku
                }
                custom_fields {
                    id
                    value
                }
            }
            state {
                warnings {
                    message
                    column
                    row
                }
                errors {
                    message
                    column
                    row
                }
            }
        }
    }
`;

const queryToState = (players) => {

    return players.map(player => {
        player = cleanObject(player);

        player = {
            ...player,
            location: player.location?.id || null,
            location_label: player.location?.name || null,

        }

        if (player.product && player.product.id) {
            player[`product_${player.product.id}`] = player.product.variant_sku;
            delete player.product;
        }

        const customFields = (player.custom_fields || []).reduce((acc, customField) => {
            acc[customField.id] = customField.value;
            return acc;
        }, {});

        player = {
            ...player,
            ...customFields,
        }

        delete player.custom_fields;

        return player;
    });
}


const CreateRowsStep = ({ subEvent, intl }) => {

    const [form] = Form.useForm();

    const [processSubEventSubscriptionsImport, { loading }] = useLazyQuery(PROCESS_SUB_EVENT_SUBSCRIPTIONS_IMPORT);

    const [importSubEventSubscriptions, { loading: loadingMutation }] = useMutation(IMPORT_SUB_EVENT_SUBSCRIPTIONS);

    const { state, setPlayers, onAddNewPlayer, setValidatorState, setStepState } = useContext(ImportPlayersContext);

    const { validatorState, editingKey } = state;

    const onUploadFile = (base64File) => {
        processSubEventSubscriptionsImport({
            variables: {
                id: subEvent.id,
                file: base64File,
                locale: getSafeISOLanguage(intl.locale),
            }
        }).then(({ data }) => {
            if (data?.processSubEventSubscriptionsImport) {
                //setStepState(2);

                const { players, state } = data.processSubEventSubscriptionsImport;

                setPlayers(queryToState(players));
                setValidatorState(state);

            } else {
                message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
            }
        }).catch((error) => {
            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
        });
    }

    const onSubmit = (players) => {
        importSubEventSubscriptions({
            variables: {
                id: subEvent.id,
                players: playersToMutation(players, subEvent),
                dryRun: true,
            }
        }).then(({ data }) => {
            if (data?.importSubEventSubscriptions) {
                if (data.importSubEventSubscriptions.errors && data.importSubEventSubscriptions.errors.length > 0) {

                    setValidatorState({
                        errors: data.importSubEventSubscriptions.errors
                    });

                } else {

                    setValidatorState({
                        errors: []
                    });

                    setStepState(2);
                }
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    return (
        <Row>
            <Col xs={24}>

                <ValidationErrors
                    subEvent={subEvent}
                    errors={validatorState.errors}
                    onClose={() => {
                        setValidatorState({
                            errors: []
                        })
                    }}
                />

                <TipsCard>
                    <IntlMessages id="import_players.add_players_tip" />
                </TipsCard>

                <Card
                    title={<IntlMessages id="import_players.add_players" />}
                    extra={
                        <Space>
                            <Upload
                                accept={ACCEPTED_FILE_MIMES.join(',')}
                                showUploadList={false}
                                multiple={false}
                                beforeUpload={async (file) => {

                                    const errors = await validateFiles(
                                        [file],
                                        {
                                            maxSize: UPLOAD_MAX_FILE_SIZE_MB,
                                            mimeTypes: ACCEPTED_FILE_MIMES,
                                        },
                                        intl
                                    );
                                    const base64File = await fileToBase64(file);

                                    onUploadFile(base64File);

                                    return false;
                                }}
                            >
                                <Button
                                    loading={loading}
                                    className="gx-mb-0"
                                >
                                    <UploadOutlined className="gx-pr-1" />
                                    <IntlMessages id="common.upload" />
                                </Button>
                            </Upload>

                            <Button
                                className="gx-mb-0"
                                onClick={() => {
                                    onAddNewPlayer()
                                }}
                            >
                                <PlusOutlined className="gx-pr-1" />
                                <IntlMessages id="common.add" />
                            </Button>
                            <Button
                                loading={loadingMutation}
                                type="primary"
                                className="gx-mb-0"
                                icon={<CheckOutlined className="gx-pr-1" />}
                                disabled={Boolean(editingKey)}
                                onClick={() => form.submit()}
                            >
                                <IntlMessages id="common.validate" />
                            </Button>
                        </Space>
                    }
                >
                    <ImportPlayersTable editable={true} form={form} subEvent={subEvent} onSubmit={onSubmit} />
                </Card>
            </Col>
        </Row>
    )
}

export default CreateRowsStep;