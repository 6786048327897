import { InMemoryCache } from '@apollo/client';
import { layoutSettingsVar, globalSettingsVar } from '../apollo/local/cache';

const apolloCache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        Project: {
            //for reference
            keyFields: ['id'],
        },
        PlayerImportProduct: {
            //for reference
            keyFields: ['id', 'variant_sku'],
        },
        PlayerImportCustomField: {
            //for reference
            keyFields: ['id', 'value'],
        },
        Query: {
            fields: {
                layoutSettings: {
                    read() {
                        return layoutSettingsVar();
                    },
                },
                globalSettings: {
                    read() {
                        return globalSettingsVar();
                    },
                },
                services: {
                    merge(existing = [], incoming = []) {
                        return incoming;
                    },
                },
                workspaces: {
                    merge(existing = [], incoming = []) {
                        return incoming;
                    },
                },
                employees: {
                    merge(existing = [], incoming = []) {
                        return incoming;
                    },
                },
                customers: {
                    merge(existing = [], incoming = []) {
                        return incoming;
                    },
                },
                users: {
                    merge(existing = [], incoming = []) {
                        return incoming;
                    },
                },
            },
        },
    },
});

export default apolloCache;
